import Api from "./api";
import { AxiosError, AxiosResponse } from "axios";

export class EventApi extends Api {
  private token = "";

  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  private getFormData = (object: Record<string, any>) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());

  public searchEvent(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("event/search", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public store(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("event", this.getFormData(data), {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public setMentorsByAdmin(
    data: Record<string, any>,
    id: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>(
      "event/set-mentor/" + id,
      this.getFormData(data),
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    );
  }

  public addPayment(
    data: Record<string, any>,
    id: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>(
      "event/add-payment/" + id,
      this.getFormData(data),
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    );
  }

  public changeStatus(
    data: Record<string, any>,
    id: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>("event/changeStatus/" + id, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public canNotBeContract(
    data: Record<string, any>,
    id: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>("event/canNotBeContract/" + id, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public cancelOrChangeTimeByAdviser(
    data: Record<string, any>,
    id: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>(
      "event/cancelOrChangeTimeByAdviser/" + id,
      data,
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    );
  }

  public remindLaterForContract(
    data: Record<string, any>,
    id: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>(
      "event/remindLaterForContract/" + id,
      data,
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    );
  }

  public userAdd(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("event/userAdd", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public quickRegisterPublic(
    data: Record<string, any>
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>("quickRegisterPublic", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public list(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("event", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public view(id: number): Promise<AxiosResponse> {
    return this.get<ServerResponse>("event/" + id, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public deleteEvent(id: string): Promise<AxiosResponse> {
    return this.delete<ServerResponse>("event/" + id, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public pay(event: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>("payment/" + event, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public confirmPayment(uid: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>("confirmPayment/" + uid);
  }

  public setConfirmPayment(
    data: Record<string, any>,
    uid: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>("confirmPayment/" + uid, data);
  }

  public GetCommentList(param: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("comments", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: param
    });
  }
  public AddComment(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("comments", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }
  public DeleteComment(
    data: Record<string, any>,
    uid: number
  ): Promise<AxiosResponse> {
    return this.delete<ServerResponse>("comments/" + uid, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }
}
