






























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { ContractApi } from "@/networks/ContractApi";
import UserAutoComplete from "@/views/components/inputs/user.vue";
import Office from "@/views/components/inputs/office.vue";
import Enum from "@/config/enum";
import MobileList from "@/views/components/pages/Contract/MobileCard.vue";
import DatetimePicker from "vue-persian-datetime-picker";
import fileUploader from "@/views/components/inputs/fileUploader.vue";
import eventsDialog from "@/views/components/pages/dashboard/eventsDialog.vue";
import Comments from "@/views/components/pages/Comments/Comments.vue";
import moment from "moment-jalaali";
@Component({
  components: {
    UserAuto: UserAutoComplete,
    office: Office,
    fileUploader,
    "datetime-picker": DatetimePicker,
    MobileList: MobileList,
    eventsDialog,
    Comments
  }
})
export default class ListEvent extends Vue {
  private data: any = [];
  private items: any = [];
  private roles = localStorage.getItem("roles") || [];
  private form: any = {};
  private page = 1;
  dialog = false;
  private baseUrl = process.env.VUE_APP_URL || "";
  mounted() {
    if (window.innerWidth < 600) {
      document.addEventListener("scroll", this.HandleScrollMobile);
    }
    if (this.$route.query.search) {
      this.form.q = this.$route.query.search;
    }
    this.contracts();
  }
  private timeout: any = null;
  HandleScrollMobile(e: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (
        e.target.scrollingElement.clientHeight +
          e.target.scrollingElement.scrollTop >=
        e.target.scrollingElement.scrollHeight - 100
      ) {
        this.page++;
      }
    }, 300);
  }
  copyLinkToClipboard(id: string): void {
    let copyText = (document as any).getElementById("pay-link-copy-" + id);
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    navigator.clipboard
      .writeText(copyText.value)
      .then(() => {
        store.commit("showError", {
          message: "لینک با موفقیت کپی شد ",
          color: "success"
        });
      })
      .catch((error) => {
        alert(`Copy failed! ${error}`);
      });
  }
  JalaaliDate(date: any) {
    return moment(date).format("jYYYY/jMM/jDD");
  }
  public async contracts(): Promise<void> {
    try {
      const res: AxiosResponse = await new ContractApi().list({
        ...this.form,
        page: this.page
      });
      if (window.innerWidth < 600) {
        this.items = [...this.items, ...res.data.data.data];
        if (res.data.data.last_page == this.page) {
          document.removeEventListener("scroll", this.HandleScrollMobile);
        }
      }
      this.data = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("page")
  public onPageChanged() {
    this.contracts();
  }

  @Watch("form.office_id")
  public onFormOfficeChanged() {
    console.log(this.form);
  }

  private change($event: any) {
    this.form.office_id = $event;
    this.contracts();
  }

  public onFormChanged() {
    this.$router.push({ query: { search: this.form.q } });
    this.fetchEntriesDebounced();
  }

  private _timerId = 0;
  fetchEntriesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = window.setTimeout(() => {
      this.page = 1;
      this.contracts();
    }, 700);
  }

  @Watch("form.MobileList_id")
  public onForm2Changed() {
    this.contracts();
  }

  @Watch("form.type")
  public onForm3Changed() {
    this.contracts();
  }

  @Watch("form.user_id")
  public onForm4Changed() {
    console.log(this.form);
    this.contracts();
  }

  @Watch("form.creator_id")
  public onForm5Changed() {
    this.contracts();
  }

  @Watch("form.mentor_id")
  public onForm6Changed() {
    this.contracts();
  }
}
