




































































import { Vue, Component, Prop } from "vue-property-decorator";
import ImageUploader from "vue-image-upload-resize";

@Component({
  components: {
    ImageUploader
  }
})
export default class ImageUploaderComponent extends Vue {
  private debug = process.env.NODE_ENV != "production";
  @Prop({ default: null }) value!: any;
  file = this.value;
  @Prop({ default: "#fff" }) fill!: string;
  @Prop({ default: "آپلود فایل" }) label!: string;
  @Prop({ default: "mdi-paperclip" }) icon!: string;
  @Prop({ default: "*" }) accept!: string;
  @Prop({ default: true }) preview!: boolean;
  random_id = Math.ceil(Math.random() * 100000);
  show_image = false;
  async setImage(output: any) {
    this.file = output;
    this.$emit(
      "input",
      await this.urltoFile(output.dataUrl, output.info.name, output.info.type)
    );
    console.log(this.file);
    console.log("Info", output.info);
    console.log("Exif", output.exif);
  }
  showImage() {
    this.show_image = true;
  }
  async urltoFile(
    url: string,
    filename: string,
    mimeType: string
  ): Promise<File> {
    return await fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }
}
