



















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { VAutocomplete } from "vuetify/lib";
import { UserApi } from "@/networks/UserApi";
import { debounce } from "vue-debounce";

@Component({
  components: {
    VAutocomplete
  }
})
export default class UserAutoComplete extends Vue {
  @Prop()
  defaultValue: number | undefined;
  @Prop()
  role: Array<string> | undefined;
  @Prop({ default: "کاربر" })
  label: string | undefined;
  @Prop()
  removeNone: any;

  private users: Array<Record<string, unknown>> = [];
  private form: Record<string, unknown> = {
    userId: 0
  };
  private isLoading = false;
  private search = "";

  mounted() {
    window.setTimeout(() => {
      this.form.userId = this.defaultValue;
    }, 1000);
  }

  public async user(): Promise<void> {
    this.isLoading = true;
    try {
      // console.log(this.form, this.label, this.defaultValue, "user")
      const res: AxiosResponse = await new UserApi().searchUser({
        ...this.form,
        q: this.search,
        role: this.role,
        value: this.defaultValue
      });
      let users = res.data.data;
      if (this.defaultValue == 0) {
        if (!this.removeNone) {
          users = [{ name: "هیچکدام", id: 0 }].concat(res.data.data);
        } else {
          users = res.data.data;
        }
      } else {
        users = res.data.data;
      }
      this.users = users.map(
        (x: { text: string; fname: string; lname: string; mobile: string }) => {
          x.text = `${x.fname} ${x.lname} - ${x.mobile.replace("+98", "0")}`;
          return x;
        }
      );
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    } finally {
      this.isLoading = false;
    }
  }

  private _timerId = 0;
  fetchEntriesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = window.setTimeout(() => {
      this.user();
    }, 700);
  }

  @Watch("search")
  public onSearchChanged(val: string, old: string) {
    if (val != old && this.form.userId == 0) {
      this.fetchEntriesDebounced();
    }
  }

  @Watch("form.userId")
  public onPageChanged() {
    this.$emit("change-value", this.form.userId);
  }
}
