import Api from "./api";
import axios, { AxiosError, AxiosResponse } from "axios";

export class ContractApi extends Api {
  private token = "";

  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  private getFormData = (object: Record<string, any>) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());

  public list(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/contract", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public store(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("contract", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public getItem(id: string | number) {
    return this.get<ServerResponse>(`/contract/${id}`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public addTransaction(id: string | number, data: any) {
    return this.post<ServerResponse>(`/contract/${id}/trx`, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public editItem(id: string | number, data: any) {
    return this.put<ServerResponse>(`/contract/${id}`, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }
}
