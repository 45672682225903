



































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import { EventApi } from "@/networks/EventApi";
@Component
export default class component_name extends Vue {
  comments: any = [];
  commentTab = 0;
  @Prop()
  item_id!: number;
  @Prop()
  type!: string;
  title: string | null = null;
  text: string | null = null;
  data = null;
  page = 1;
  titleRules = [(v: string) => !!v || "موضوع نباید خالی باشد"];
  desRules = [(v: string) => !!v || "توضیحات نباید خالی باشد"];
  private async mounted() {
    this.getComment();
  }
  private async deleteComment(comment_id: number) {
    const bodyform = {
      item_id: this.item_id,
      type: this.type,
      title: this.title,
      text: this.text
    };
    await new EventApi().DeleteComment(bodyform, comment_id);
    this.getComment();
  }
  private async getComment() {
    const form = {
      item_id: this.item_id,
      type: this.type,
      page: this.page
    };
    const { data }: AxiosResponse = await new EventApi().GetCommentList(form);
    this.comments = data.data.data;
    this.data = data.data;
  }
  private async add_comment() {
    const validate = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    if (!validate) {
      return;
    }
    let bodyform: any = {
      item_id: this.item_id,
      type: this.type,
      title: this.title,
      text: this.text
    };
    try {
      await new EventApi().AddComment(bodyform);
      let user = JSON.parse(localStorage.getItem("user") || "");
      bodyform = {
        ...bodyform,
        user: {
          full_name: user.fname + " " + user.lname
        }
      };
      this.comments.unshift(bodyform);
      this.title = "";
      this.text = "";
      this.commentTab = 0;
      this.$store.commit("showError", {
        message: "کامنت شما با موفقیت ثبت شد",
        color: "success"
      });
    } catch (err) {
      this.$store.commit("showError", {
        message: "کامنت شما ثبت نشد",
        color: "red"
      });
    }
  }
  @Watch("page")
  public onPageChanged() {
    this.getComment();
  }
}
