



















































































































































































































































































































































































































































import Enum from "@/config/enum";
import { EventApi } from "@/networks/EventApi";
import { UserApi } from "@/networks/UserApi";
import store from "@/store";
import Office from "@/views/components/inputs/office.vue";
import { AxiosResponse } from "axios";
import moment from "moment-jalaali";
import DatetimePicker from "vue-persian-datetime-picker";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { Office, DatetimePicker }
})
export default class events_dialogs extends Vue {
  canNotBeContract = 0;
  cancelDialog = false;
  online = Enum.onlineTypes;
  today = moment().format("YYYY-MM-DD");
  roles = localStorage.getItem("roles") || [];
  user = JSON.parse(localStorage.getItem("user") as string);
  private errors: Record<string, Array<string>> = {};
  private item: Record<string, any> = {};
  @Prop()
  dialogItem!: Record<string, any>;

  mounted() {
    if (this.dialogItem.item) {
      this.item = this.dialogItem.item;
    } else {
      this.item = this.dialogItem;
    }
    for (let i = 9; i <= 21; i++) {
      this.times.push({
        text: `ساعت ${i}:00`,
        value: `${("00" + i).substr(-2)}:00`,
        cnt: "0"
      });
    }
  }

  public async couldNotBeContractAdviser(type: string): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().cancelOrChangeTimeByAdviser(
        {
          type,
          date: this.item.date,
          office_id: this.item.office_id,
          time: this.item.time
        },
        this.item.id
      );
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("refresh-list");
      this.$emit("close");
      this.canNotBeContract = 0;
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  private times: Array<Record<string, string>> = [];

  @Watch("item.date")
  public onDialogItemChange(val: string, oldVal: string): void {
    if (val && val != oldVal && oldVal) {
      this.checkAvailability();
    }
  }

  @Watch("item.office_id")
  public onDialogItemOfficeChange(val: string, oldVal: string): void {
    if (val && val != oldVal && oldVal) {
      this.checkAvailability();
    }
  }

  public async checkAvailability(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().checkAvailability({
        office_id: `${this.item.office_id}`,
        date: this.item.date,
        type: this.item.type_original
      });
      const times: Record<string, string>[] = [];
      this.times.map((x) => {
        let insert = false;
        res.data.data.forEach((y: { time: string; cnt: string }) => {
          if (y.time.substr(0, 5) == x.value) {
            times.push({
              text: `ساعت ${x.value} (${y.cnt ? "" : "(ناموجود)"}`,
              value: `${x.value}`,
              cnt: `${y.cnt}`
            });
            insert = true;
          }
        });
        if (!insert) {
          times.push(x);
        }
      });
      this.times = times;
    } catch (error: any) {
      if (error.response.status == 422) {
        // this.errors = error.response?.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async couldNotBeContract(): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().canNotBeContract(
        {
          explanation: this.item.explanation
        },
        this.item.id
      );
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("refresh-list");
      this.$emit("close");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async remindLater(): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().remindLaterForContract(
        {
          explanation: this.item.explanation,
          date: this.item.date
        },
        this.item.id
      );
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("refresh-list");
      this.$emit("close");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async cancelEvent(): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().changeStatus(
        {
          is_done: 2,
          summery: this.item.summery
        },
        this.item.id
      );
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("refresh-list");
      this.$emit("close");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  contract(): void {
    if (this.item.mentors && this.item.mentors.length > 0) {
      let is_editable = this.item.mentors.filter(
        (x: any) =>
          x.id == this.user.id || (this.roles as any).includes("super-admin")
      );
      if (is_editable && is_editable[0]) {
        this.$router.push({
          name: "contract",
          params: { id: this.item.id }
        });
      } else {
        store.commit("showError", {
          message: "شما اجازه ویرایش این رویداد را ندارید",
          color: "danger"
        });
      }
    } else {
      this.$router.push({
        name: "contract",
        params: { id: this.item.id }
      });
    }
  }
}
